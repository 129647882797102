import { DEEPLINKS } from '../constants/deeplinks';

const createHpc3Regex = (path) => new RegExp(`^/[a-z]{2}/[a-z]{2}${path}$`);

export const getRedirectToHpc3Info = (path) => {
  const hpc3Pages = [
    DEEPLINKS.LEGACY_PORTAL.MY_PRINTER,
    DEEPLINKS.LEGACY_PORTAL.MY_PRINTERS,
    DEEPLINKS.LEGACY_PORTAL.NEW_PRINTER
  ].map((deeplink) => ({ regexRule: createHpc3Regex(deeplink), deeplink }));

  const matchingPage = hpc3Pages.find((page) => {
    return page.regexRule.test(path);
  });

  return {
    shouldRedirectToHpc3: !!matchingPage,
    deeplinkToRedirect: matchingPage ? matchingPage.deeplink : ''
  };
};
