import deDE from './de_DE.json';
import enUS from './en_US.json';
import esES from './es_ES.json';
import frCA from './fr_CA.json';
import frFR from './fr_FR.json';
import itIT from './it_IT.json';
import ptBR from './pt_BR.json';
import zhCN from './zh_CN.json';
import bgBG from './bg_BG.json';
import csCZ from './cs_CZ.json';
import daDK from './da_DK.json';
import elGR from './el_GR.json';
import enGB from './en_GB.json';
import etEE from './et_EE.json';
import fiFI from './fi_FI.json';
import hrHR from './hr_HR.json';
import huHU from './hu_HU.json';
import jaJP from './ja_JP.json';
import koKR from './ko_KR.json';
import ltLT from './lt_LT.json';
import lvLV from './lv_LV.json';
import nbNO from './nb_NO.json';
import nlNL from './nl_NL.json';
import plPL from './pl_PL.json';
import ptPT from './pt_PT.json';
import roRO from './ro_RO.json';
import ruRU from './ru_RU.json';
import skSK from './sk_SK.json';
import slSI from './sl_SI.json';
import svSE from './sv_SE.json';
import trTR from './tr_TR.json';
import zhHK from './zh_HK.json';
import zhTW from './zh_TW.json';

export default {
  bg_BG: bgBG,
  cs_CZ: csCZ,
  da_DK: daDK,
  de_AT: deDE,
  de_CH: deDE,
  de_DE: deDE,
  de_LI: deDE,
  de_LU: deDE,
  el_CY: elGR,
  el_GR: elGR,
  en_AE: enUS,
  en_AF: enUS,
  en_AG: enUS,
  en_AI: enUS,
  en_AL: enUS,
  en_AU: enUS,
  en_AW: enUS,
  en_AZ: enUS,
  en_BA: enUS,
  en_BB: enUS,
  en_BD: enUS,
  en_BH: enUS,
  en_BN: enUS,
  en_BS: enUS,
  en_BT: enUS,
  en_BW: enUS,
  en_BZ: enUS,
  en_CA: enUS,
  en_CM: enUS,
  en_CR: enUS,
  en_CY: enUS,
  en_DZ: enUS,
  en_EG: enUS,
  en_ET: enUS,
  en_GB: enGB,
  en_GD: enUS,
  en_GE: enUS,
  en_GH: enUS,
  en_HK: enUS,
  en_ID: enUS,
  en_IE: enUS,
  en_IL: enUS,
  en_IN: enUS,
  en_IS: enUS,
  en_JM: enUS,
  en_JO: enUS,
  en_JP: enUS,
  en_KE: enUS,
  en_KH: enUS,
  en_KN: enUS,
  en_KW: enUS,
  en_KY: enUS,
  en_LA: enUS,
  en_LB: enUS,
  en_LC: enUS,
  en_LK: enUS,
  en_LY: enUS,
  en_MA: enUS,
  en_ME: enUS,
  en_MK: enUS,
  en_MM: enUS,
  en_MN: enUS,
  en_MS: enUS,
  en_MT: enUS,
  en_MU: enUS,
  en_MV: enUS,
  en_MY: enUS,
  en_NA: enUS,
  en_NG: enUS,
  en_NP: enUS,
  en_NZ: enUS,
  en_OM: enUS,
  en_PH: enUS,
  en_PK: enUS,
  en_PR: enUS,
  en_QA: enUS,
  en_RS: enUS,
  en_RW: enUS,
  en_SA: enUS,
  en_SG: enUS,
  en_SX: enUS,
  en_TC: enUS,
  en_TH: enUS,
  en_TL: enUS,
  en_TN: enUS,
  en_TT: enUS,
  en_TW: enUS,
  en_TZ: enUS,
  en_UG: enUS,
  en_US: enUS,
  en_VC: enUS,
  en_VG: enUS,
  en_VI: enUS,
  en_VN: enUS,
  en_ZA: enUS,
  en_ZM: enUS,
  es_AR: esES,
  es_BO: esES,
  es_CL: esES,
  es_CO: esES,
  es_CU: esES,
  es_DO: esES,
  es_EC: esES,
  es_ES: esES,
  es_GT: esES,
  es_HN: esES,
  es_MX: esES,
  es_NI: esES,
  es_PA: esES,
  es_PE: esES,
  es_PR: esES,
  es_PY: esES,
  es_SV: esES,
  es_UY: esES,
  es_VE: esES,
  et_EE: etEE,
  fi_FI: fiFI,
  fr_BE: frFR,
  fr_BJ: frFR,
  fr_BL: frFR,
  fr_CA: frCA,
  fr_CH: frFR,
  fr_CI: frFR,
  fr_FR: frFR,
  fr_GF: frFR,
  fr_GP: frFR,
  fr_HT: frFR,
  fr_LC: frFR,
  fr_LU: frFR,
  fr_MF: frFR,
  fr_MG: frFR,
  fr_MQ: frFR,
  fr_NC: frFR,
  fr_PF: frFR,
  fr_RE: frFR,
  fr_SN: frFR,
  fr_YT: frFR,
  hr_HR: hrHR,
  hu_HU: huHU,
  it_CH: itIT,
  it_IT: itIT,
  ja_JP: jaJP,
  ko_KR: koKR,
  lt_LT: ltLT,
  lv_LV: lvLV,
  nb_NO: nbNO,
  nl_BE: nlNL,
  nl_BQ: nlNL,
  nl_CW: nlNL,
  nl_NL: nlNL,
  pl_PL: plPL,
  pt_AO: ptPT,
  pt_BR: ptBR,
  pt_MZ: ptPT,
  pt_PT: ptPT,
  ro_MD: roRO,
  ro_RO: roRO,
  ru_BY: ruRU,
  ru_KZ: ruRU,
  ru_RU: ruRU,
  ru_UA: ruRU,
  ru_UZ: ruRU,
  sk_SK: skSK,
  sl_SI: slSI,
  sv_FI: svSE,
  sv_SE: svSE,
  tr_TR: trTR,
  zh_CN: zhCN,
  zh_HK: zhHK,
  zh_TW: zhTW
};
