import React, { useCallback, useState, useMemo } from 'react';
import { JarvisOnboardingComponent } from '@jarvis/react-user-onboarding/dist/components';
import { UserRequestedSignOutError } from '@jarvis/react-user-onboarding/dist/errors';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import { userTenantApiCall } from 'src/api/userTenantApiCall';
import getBaseUrl from 'src/utils/getBaseUrl';
import { ErrorModal } from '../ErrorModal';
import { Stack } from '../../types/shell';
import { USER_ROLES } from 'src/constants/UserRoles';
import { TENANT_TYPES } from 'src/constants/TenantTypes';
import { ALREADY_CREATED_ACCOUNT_ERROR_CODES } from '../../constants/AccountMgmtApiErrorsCode';
import {
  goToPortal,
  getUrlSearchParams,
  getJshellCompatibleUrl,
  urlShouldRedirectToMyPrintersPage
} from '../../utils/routing';
import { checkHttpCodeShouldForceLogin } from '../../utils/api';
import { getCookie } from '../../utils/cookie';
import { IS_AGENT_SESSION } from '../../constants/cookieNames';
import { DEEPLINKS } from '../../constants/deeplinks';
import { getRedirectToHpc3Info } from '../../utils/redirect';

const UserOnboarding = ({ mfeProperties }) => {
  const [errorModal, setErrorModal] = useState({
    show: false,
    params: null
  });

  const {
    shell: { v1, v2 },
    stack
  } = useRootContext();

  const { t } = useI18n();

  const {
    localization: { country, language },
    authProvider: shellAuthProvider,
    tenantHandlerInterface,
    navigation
  } = v1;

  const {
    authProvider: { createOrglessAuthProvider }
  } = v2;

  const {
    invite: inviteFromURL,
    orgId: orgIdFromURL,
    redirectTo: redirectToFromURL
  } = getUrlSearchParams({
    navigation
  });

  const postOnboardingRedirectPath = useMemo(() => {
    if (urlShouldRedirectToMyPrintersPage({ url: redirectToFromURL })) {
      return DEEPLINKS.PORTAL.MY_PRINTERS;
    }

    return (
      getJshellCompatibleUrl({ url: redirectToFromURL }) ||
      DEEPLINKS.PORTAL.HOME
    );
  }, [redirectToFromURL]);

  const getLegacyPortalPageUrl = (deeplinkPage) => {
    return `https://www.hpsmart${
      stack === Stack.prod ? '' : Stack[stack]
    }.com/${country}/${language}${deeplinkPage}`;
  };

  const getTenantId = useCallback(() => {
    try {
      return tenantHandlerInterface.getTenantId();
    } catch (error) {
      return '';
    }
  }, [tenantHandlerInterface]);

  const handleContactSupportClick = () => {
    const supportUrl = `https://support.hp.com/${country}-${language}/contact`;
    window.open(supportUrl, '_blank');
  };

  const authProvider = useMemo(
    () => ({
      ...shellAuthProvider,
      onTokenExchangeRequired: async (props) => {
        const accountId = props?.accountId || '';

        if (accountId) {
          if (accountId !== getTenantId()) {
            await tenantHandlerInterface.setTenant({
              tenantId: accountId,
              authContext: 'tenant',
              preventReload: true
            });
          }
        }
      },
      getOrglessAccessToken: async (requireRefresh) => {
        if (requireRefresh) {
          await shellAuthProvider.getAccessToken(true);
        }

        const orgLessAuthProvider = await createOrglessAuthProvider();
        return await orgLessAuthProvider.getAccessToken();
      }
    }),
    [
      createOrglessAuthProvider,
      getTenantId,
      shellAuthProvider,
      tenantHandlerInterface
    ]
  );

  const handleOnboardingFinished = async (arg) => {
    if (inviteFromURL === 'true') {
      const userWithTenantDetails = await userTenantApiCall({
        authProvider: shellAuthProvider,
        stack
      });

      const isUserRole =
        userWithTenantDetails?.userTenantDetail?.roleCategory?.toLowerCase() ===
        USER_ROLES.User;

      const isTenantTypeBusinessTransactionalSMB =
        userWithTenantDetails?.userTenantDetail?.tenantType ===
        TENANT_TYPES.BusinessTransactionalSMB;

      if (isUserRole && isTenantTypeBusinessTransactionalSMB) {
        window.location.href = getLegacyPortalPageUrl(
          DEEPLINKS.LEGACY_PORTAL.GET_SOFTWARE
        );
        return;
      }
    }

    if (!arg) {
      const { shouldRedirectToHpc3, deeplinkToRedirect } =
        getRedirectToHpc3Info(redirectToFromURL);

      if (shouldRedirectToHpc3) {
        window.location.href = getLegacyPortalPageUrl(deeplinkToRedirect);
        return;
      }

      goToPortal({ navigation, url: postOnboardingRedirectPath });
      return;
    }

    if (arg instanceof UserRequestedSignOutError) {
      shellAuthProvider.forceLogin();
      return;
    }

    const { response } = arg;

    if (!response) {
      setErrorModal({ show: true, params: arg });
      return;
    }

    const { status } = response;

    checkHttpCodeShouldForceLogin({
      authProvider: shellAuthProvider,
      httpCode: status
    });
  };

  const handleOnboardingError = async (error) => {
    checkHttpCodeShouldForceLogin({
      authProvider: shellAuthProvider,
      httpCode: error?.code
    });

    const errors = error?.payload?.response?.data?.errors;

    /*
     * OCPRI-1062: The ListPersonOrgs API can sometimes return an empty organization category type,
     * and the error code in this case is the same as EB000U0217. This situation can lead to an
     * infinite loop of redirections. To prevent this, a check to see if the user has already been
     * redirected once is done. If a redirection has already occurred, we avoid further redirections.
     */
    const hasRedirected = sessionStorage.getItem(
      'multipleRedirectsOnboardingErrorHandled'
    );

    if (hasRedirected) {
      sessionStorage.removeItem('multipleRedirectsOnboardingErrorHandled');
      return;
    }

    if (
      errors?.length &&
      errors.find((e) => ALREADY_CREATED_ACCOUNT_ERROR_CODES[e.code]) &&
      !hasRedirected
    ) {
      goToPortal({ navigation, url: DEEPLINKS.PORTAL.HOME });
      sessionStorage.setItem('multipleRedirectsOnboardingErrorHandled', 'true');
    }
  };

  const uiMgtSvcConfig = {
    ...mfeProperties?.uiMgtSvcConfig
  };

  const getAccountMgtSvcConfig = useCallback(() => {
    const accountId = getTenantId() || orgIdFromURL;

    if (accountId) {
      return {
        account: {
          accountId
        }
      };
    }

    return {
      account: {
        accountName: t('home.myPrinters'),
        type: 'Personal'
      }
    };
  }, [getTenantId, orgIdFromURL, t]);

  const isOAuthToken = useCallback(async () => {
    const jwtPayload = await authProvider.getDecodedUserStratusJWTPayload();
    return jwtPayload?.iss?.includes('oauth-auth');
  }, [authProvider]);

  const baseUrlProvider = useCallback(
    async (apiName, apiVersion) => {
      return getBaseUrl(
        apiVersion,
        apiName,
        await isOAuthToken(),
        Stack[stack]
      );
    },
    [isOAuthToken, stack]
  );

  if (getCookie(IS_AGENT_SESSION)) {
    goToPortal({ navigation, url: postOnboardingRedirectPath });
    return null;
  }

  if (errorModal.show) {
    return <ErrorModal {...errorModal.params} />;
  }

  return (
    <JarvisOnboardingComponent
      country={country.toUpperCase()}
      language={language}
      baseURLProvider={baseUrlProvider}
      authProvider={authProvider}
      uiMgtSvcConfig={uiMgtSvcConfig}
      onFinished={handleOnboardingFinished}
      onError={handleOnboardingError}
      accountMgtSvcConfig={getAccountMgtSvcConfig()}
      onContactSupportClick={handleContactSupportClick}
    />
  );
};

export default UserOnboarding;
